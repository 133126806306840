import React from 'react'
import { navigate } from "gatsby";
import { StaticImage } from 'gatsby-plugin-image'

import {
    center,
} from '../components/layout.module.css'

const SsoMap = () => (
  <div>
    <button onClick={() => navigate(-1)}>Go Back</button>
    <StaticImage className={center} style={{height:'90vh'}} objectFit="contain" src='../images/sso-map.jpg' alt="Map of Massachusetts listing out social services organizations: Project Bread, Elder Services of Worcester Area, South Middlesex Opportunity Council, Making Opportunity Count, Eliot, Community Servings, Massachusetts Coalition for the Homeless, Project Hope, Father Bill's, Wayfinders, Community Action Pioneer Valley, Behavioral Health Network, The Food Bank of Western Massachusetts, and the Housing Assistance Corporation Cape Cod"></StaticImage>
  </div>
)

export default SsoMap